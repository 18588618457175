export default [
  {
    fileName: "client/up-dejun-up-online-clienti.png",
    link: "https://upromania.ro/companii/card-de-tichete-de-masa-up-dejun/",
    category: "Banner",
    action: "Click",
    label: "Client_card_dejun",
  },
  {
    fileName: "client/up-onlline-clienti.png",
    link:
      "https://upromania.ro/companii/card-cultural/",
    category: "Banner",
    action: "Click",
    label: "campanie_card_cultura",
  },
];
